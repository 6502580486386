<script>

import AkLabel from "@components/general/AkLabel";

export default {
  components: {AkLabel},
  inject: {'pSubmitted': {default: null}, 'pDisabled': {default: null}},
  props: {
    label: {
      type: String,
      required: true,
    },
    modelValue: {
      required: true
    },
    className: {
      type: String,
      required: false,
      default: "col-md-6"
    },
    validator: {
      type: Object,
      required: false,
      default: undefined
    },
    submitted: {
      type: Boolean,
      required: false,
      default: undefined
    },
    disabled: {
      type: Boolean,
      required: false,
      default: undefined
    },
    returnType: {type: String, default: "String"},
    options: {
      type: Array,
      default: undefined
    },
    optionLabel: {
      type: [String, Function],
      required: false,
      default: "label"
    },
    optionValue: {
      type: [String, Function],
      required: false,
      default: "value"
    },
    optionDisabled: {
      type: String,
      default: "disabled"
    },
    multiple: {
      type: Boolean,
      default: true
    }
  },
  emits: ["update:modelValue"],
  data() {
    return {
      id: null
    }
  },
  computed: {
    currentValue: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      },
    },
    alreadySubmitted() {
      if (this.submitted !== undefined) return this.submitted;
      if (this.pSubmitted !== undefined) return this.pSubmitted;
      return false;
    },
    isDisabled() {
      if (this.disabled !== undefined) return this.disabled;
      if (this.pDisabled !== undefined) return this.pDisabled;
      return false;
    },
    isInvalid() {
      return this.validator !== undefined && this.validator.$invalid && this.alreadySubmitted;
    }
    ,
    isRequired() {
      return this.validator !== undefined && this.validator.required !== undefined;
    },
    isValidatorRequired() {
      return this.validator !== undefined && this.validator.required !== undefined && this.validator.required.$invalid && this.alreadySubmitted;
    }
    ,
  },
  mounted() {
    this.id = "id_" + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  },
  methods: {}
}
</script>

<template>
  <div
      :class="className"
      class="form-group ak-checkbox-button"
  >
    <AkLabel :required="isRequired" :style="this.label === '' || this.label === undefined ? 'display: none;' : ''">
      {{ label }}
    </AkLabel>
    <SelectButton
        v-model="currentValue"
        :disabled="isDisabled"
        :multiple="multiple"
        :option-disabled="optionDisabled"
        :option-label="optionLabel"
        :option-value="optionValue"
        :options="options"
    />
    <div>
      <small
          v-if="isValidatorRequired"
          class="p-error"
      >{{
          $t("field_required", [label])
        }}</small>
    </div>
  </div>
</template>

<style>

.p-selectbutton.p-component.p-disabled {
  background: transparent !important;
}
.p-selectbutton.p-disabled .p-button {
  background: #e9ecef;
}

.p-selectbutton.p-disabled .p-button.p-highlight {
  background: #2196F3;
}

</style>